/* 
 * @author    ThemePunch <info@themepunch.com>
 * @link      http://www.themepunch.com/
 * @copyright 2017 ThemePunch
*/

.rs-particles-canvas {

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.5s linear;	
	-webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
	user-select: none;

}

/* interactive, no slidelink */
.rs-particles-interactive .rs-particles-canvas {
	
	pointer-events: auto;
	
}

/* interactive + slidelink */
.rs-particles-slidelink .rs-particles-canvas {
	
	cursor: pointer;
	pointer-events: auto;
	
}